<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline">Import Data</span>
        <v-spacer />
        <v-btn class="mt-3" color="primary" href="/empact-individual-registered-events-template.csv" target="_blank">
          <v-icon>mdi-download</v-icon>
          <span class="ml-2">Download Example CSV</span>
        </v-btn>
      </v-card-title>
      <div class="p-3 pb-0 d-flex align-baseline">
        <v-file-input v-model="selectedFile" name="csvFile" accept=".csv" placeholder="Pick an CSV File" label="Pick an CSV File" hide-details></v-file-input>
        <v-btn :disabled="!selectedFile" class="ml-5" color="secondary" @click="analyseFile">Analyse</v-btn>
      </div>
      <div class="pl-4 mb-4 font-weight-medium text-caption">Note: Do Not Change Column Names!</div>

      <div class="p-3" v-if="fileAnalysed">
        <div v-if="!fileValid">The file seems invalid.</div>
        <div v-if="!headersValid">The file seems invalid.</div>
        <v-alert v-if="analysedDataInvalid" type="warning" dense>
          <div class="text-body-2">The file seems including invalid records. Please check and fix records which are colored red. You can upload it anyway, but invalid records may not process.</div>
        </v-alert>

        <v-btn v-if="fileAnalysed" block :disabled="!selectedFile" color="primary" @click="uploadFile">Upload</v-btn>

        <v-divider class="mt-5" />

        <div class="mt-4 text-body-2 font-weight-medium text-center">Data Preview</div>
        <v-data-table disable-pagination :items-per-page="-1" :hide-default-footer="true" class="mt-4" :headers="dataTableHeaders" :items="analysedData">
          <template v-slot:item.Attended="{ item }">
            <div v-if="item.AttendedValid">{{ item.Attended }}</div>
            <div v-else class="red--text">{{ item.Attended }}</div>
          </template>
        </v-data-table>
      </div>
    </v-card>
  </div>
</template>

<script>
import { postIndividualRegisteredEvents } from '@/helpers/api';
import * as CSV from '@/helpers/csv';

export default {
  name: 'ImportIndividualRegisteredEvents',
  data() {
    return {
      selectedFile: null,
      fileValid: false,
      headersValid: false,
      fileAnalysed: false,
      analysedData: [],
      analysedDataInvalid: false,
      defaultHeaders: [
        { key: 'EventName', label: 'Event Name' },
        { key: 'IndividualEmail', label: 'Email' },
        { key: 'OptedIn', label: 'Opted-In' },
        { key: 'Attended', label: 'Attended' },
      ],
    };
  },
  computed: {
    dataTableHeaders() {
      return this.defaultHeaders.map((header) => {
        return {
          text: header.label,
          align: 'start',
          sortable: false,
          value: header.key,
        };
      });
    },
    defaultHeaderKeys() {
      return this.defaultHeaders.map((header) => header.key);
    },
  },
  methods: {
    async analyseFile() {
      this.fileAnalysed = false;
      /* eslint-disable */
      const result = await CSV.parse(this.selectedFile);

      console.log(result);

      const fileValid = this.validateFile(result);

      if (!fileValid) {
        return;
      }

      const headersValid = this.validateHeaders(result.headers);

      if (!headersValid) {
        return;
      }

      this.analyseData(result.data);

      this.fileAnalysed = true;
    },
    validateFile(result) {
      const valid = result.data && result.headers && result.separator;
      this.fileValid = valid;
      return valid;
    },
    validateHeaders(headers) {
      const headersMapped = headers.map((i) => i.trim());

      const valid = JSON.stringify(this.defaultHeaderKeys) === JSON.stringify(headersMapped);
      this.headersValid = valid;
      return valid;
    },
    analyseData(data) {
      this.analysedData = data.map((line) => {
        return {
          EventName: line.EventName?.trim(),
          EventNameValid: line.EventName?.trim() || false,
          IndividualEmail: line.IndividualEmail?.trim(),
          IndividualEmailValid: line.IndividualEmail?.trim() || false,
          OptedIn: line.OptedIn?.trim(),
          OptedInValid: parseInt(line.OptedIn?.trim()) === 1 || parseInt(line.OptedIn?.trim()) === 0,
          Attended: line.Attended,
          AttendedValid: parseInt(line.Attended?.trim()) === 1 || parseInt(line.Attended?.trim()) === 0,
        };
      });

      this.analysedDataInvalid = this.analysedData.some((data) => !data.EventNameValid || !data.IndividualEmailValid || !data.OptedInValid || !data.AttendedValid);
    },
    async uploadFile() {
      this.uploadSuccessful = false;
      this.dataProcessing = true;
      const formData = new FormData();
      formData.append('csvFile', this.selectedFile);

      try {
        const result = await CSV.parse(this.selectedFile);

        if (result) {
          formData.append('separator', result.separator);

          const response = await postIndividualRegisteredEvents(formData);
          this.dataProcessing = false;
          console.log(response);

          if (response?.state) {
            this.$toast.success('CSV file uploaded successfully.');
          }
        } else {
          this.$toast.error('Failed to parse CSV. Validate your CSV file.');
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style scoped></style>
